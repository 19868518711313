@import "../../../assets/theme/_var.scss";

// .Shimmer {
//   // background-image: linear-gradient(to right, #f6f6f6c7 8%, #f0f0f0e0 18%, #f6f6f6 33%);
//   background-repeat: no-repeat;
//   background-size: 800px 104px;
//   border-radius: 4px;
//   display: inline-block;
//   position: relative;
//   // animation: placeholderShimmer linear infinite forwards !important;
//   animation-name: placeholderShimmer;
//   animation-timing-function: linear;
//   animation-iteration-count: infinite;
//   animation-fill-mode: forwards;
//   background: $shimmer;
// }
// @-webkit-keyframes placeholderShimmer {
//   0% {
//     background-position: -468px 0;
//   }

//   100% {
//     background-position: 468px 0;
//   }
// }
// @keyframes placeholderShimmer {
//   0% {
//     background-position: -468px 0;
//   }

//   100% {
//     background-position: 468px 0;
//   }
// }


.Shimmer {
  width: 200px;
  height: 30px;

  // background-color: $shimmer;
  @include theme() {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    background: theme-get("sidebar");

    :global {
      @keyframes shimmer {
        0% {
          transform: translateY(-50%) translateX(-100%);
        }

        50%,
        100% {
          transform: translateY(-50%) translateX(70%);
        }
      }
    }

    &::after {
      content: "";
      width: 300%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: theme-get("shimmer");
      animation: shimmer 2s ease-in-out infinite;
    }
  }
}