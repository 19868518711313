@import '../../../../assets/theme/_var.scss';

.stakingData {

    .listPerc {
        display: flex;
        align-items: center;
        margin: 2rem 0 3rem;

        @media (max-width:1399px) {
            margin: 2rem 0 2.6rem;
        }

        @media (max-width:1199px) {
            margin: 2rem 0;
        }

        @media (max-width:991px) {
            margin: 1.5rem 0;
        }


        li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0.5rem;
            border-radius: 0.8rem;
            padding: 0.5rem 2.4rem;
            cursor: pointer;

            @media (max-width:991px) {
                padding: 0.5rem 1.5rem;
            }

            @media (max-width:479px) {
                padding: 0.5rem 1.3rem;
            }

            @include theme() {
                background: theme-get("cardfieldBg");
            }

            &.active,
            &:hover {
                background: $gradient !important;

                span {
                    color: $white !important;
                    opacity: 1;
                }
            }

            span {
                font-family: $fontSecondry;
                font-size: 1.6rem;
                font-weight: 500;
                opacity: 0.6000000238418579;
                line-height: 2.4rem;

                @media (max-width:991px) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }

                @include theme() {
                    color: theme-get("textclr");
                }
            }
        }
    }

    .periodStaking {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.3rem 1.9rem;
        border-radius: 0.8rem;
        border: 0.1rem solid rgba($white, 0.1);

        @include theme() {
            background: theme-get("cardfieldBg");
        }

        .selectStaking {
            .common_select_inner {
                .react-select__control {
                    border: none;

                    @include theme() {
                        background: theme-get("bgWhite01sec");
                    }


                    .react-select__value-container {

                        .react-select__single-value,
                        .react-select__placeholder {
                            @include theme() {
                                color: theme-get("textclr");
                            }
                        }
                    }
                }
            }
        }
    }

    .rewardStaking {
        padding: 2rem;
        border-radius: 0.8rem;
        border: 1px solid rgba($white, 0.1);
        margin: 2rem 0 1.5rem;

        @include theme() {
            background: theme-get("cardfieldBg");
        }

        h6 {
            font-family: $fontSecondry;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.4rem;
            margin-bottom: 1.5rem;
        }

        .rewardList {
            border-top: 0.1rem solid rgba(#6b6a74, 0.1);
            padding-top: 1.3rem;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.6rem 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                label {
                    text-align: left;
                    font-size: 1.4rem;
                    font-weight: 500;
                    opacity: 0.6;
                    line-height: 1;
                    display: flex;
                    align-items: center;

                    @include theme() {
                        color: theme-get("textclr");
                    }

                    svg {
                        margin-left: 0.6rem;

                        path {
                            @include theme() {
                                fill: theme-get("textclr");
                            }
                        }
                    }
                }

                p {
                    font-size: 1.4rem;
                    line-height: 1;
                    font-weight: 500;
                    opacity: 0.6;

                    span {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

.activeCardtoken {
    border-radius: 1rem;
    background: $gradient;
    padding: 2.3rem 1.8rem;

    @media (max-width:575px) {
        padding: 2.5rem 1.4rem;
    }

    .listToken {
        display: flex;
        flex-direction: column;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.8rem;

            &:last-child {
                margin-bottom: 0;
            }

            .inputwithBalance {
                display: flex;
                align-items: center;
            }

            .common_input {
                margin-bottom: 0;
                flex: 0 0 50%;

                @media (max-width:479px) {
                    flex: 0 0 35%;
                }

                &_inner {
                    input {
                        height: 4rem;
                        padding: 0 1rem 0 0;
                        font-size: 2.4rem;
                        font-weight: 500;
                        line-height: 1;
                        letter-spacing: 0.04rem;
                    }
                }

                &.inputActive {
                    input {
                        color: $white;

                        &::placeholder {
                            color: $white !important;
                        }

                        &::-webkit-input-placeholder {
                            color: $white !important;
                        }

                        &:-moz-placeholder {
                            color: $white !important;
                        }
                    }
                }
            }

            .listRight {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 0 0 50%;

                .maxBtn {
                    padding: 0.2rem 0.8rem;
                    height: 2.2rem;
                    background: $white;
                    color: $black;
                    font-size: 1.2rem;
                    margin-right: 0.9rem;
                }
            }

            h5 {
                color: $white;
                font-family: $fontSecondry;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1;

                @media (max-width:991px) {
                    font-size: 1.4rem;
                }
            }

            h6 {
                font-family: $fontSecondry;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1;
                text-transform: capitalize;

                // color: $lightWhite;
                @include theme() {
                    color: theme-get(textclrfade);
                }

                @media (max-width:991px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

.unstakeTab {
    p {
        text-align: center;
        font-family: $fontSecondry;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 500;
        opacity: 0.6;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 52rem;
        margin: 0 auto 2.7rem;
        word-break: break-word;
        text-transform: capitalize;
    }

    h6 {
        margin-top: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 500;
        opacity: 0.6;
    }
}