@import "../../../assets/theme/_var.scss";

.header {
  width: 100%;
  z-index: $header;
  margin-bottom: 4.6rem;
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }

  @include theme() {
    background: theme-get("headerbg");
  }

  .header_in {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0rem;

    .header_logo {
      width: 100%;
      max-width: 18rem;

      @media (max-width: 575px) {
        max-width: 5rem;
      }

      img {
        width: 100%;
      }
    }

    .header_action {
      margin-left: auto;
      padding-left: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .connect_btn {
        padding: 0 2rem;
        position: relative;
        margin: 0 2rem;

        @media (max-width: 575px) {
          margin: 0 1rem;
          padding: 0 1rem;
        }

        @media (max-width: 479px) {
          margin: 0 0.5rem;
          padding: 0 0.5rem;
        }

        &::before,
        &::after {
          content: "";
          width: 0.1rem;
          height: 2.4rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @include theme() {
            background: theme-get("side_border");
          }
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }

        .common_btn {
          font-size: 1.4rem;

          @include theme() {
            font-weight: theme-get("connect_wallet_font");
          }

          width: 13.2rem;
          height: 4rem;

          @media (max-width: 575px) {
            width: 10rem;
            height: 3.8rem;
          }

          @media (max-width: 479px) {
            width: 9rem;
            height: 3.6rem;
            font-size: 1.2rem;
          }

          @include theme() {
            color: theme-get("textclr");
          }

          &::before {
            opacity: 0;
            transition: unset;
          }

          @include theme() {
            background: linear-gradient(
                  180deg,
                  theme-get("headerbg"),
                  theme-get("headerbg")
                )
                padding-box,
              $gradient border-box;
            border: 1px solid transparent;
          }

          &:hover {
            color: $white;

            &::before {
              opacity: 1;
            }
          }
        }
      }

      .header_select {
        .select__control {
          cursor: pointer;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.1);
          border: 0;
          box-shadow: none;
          height: 4rem;
          width: 7.3rem;

          @media (max-width: 399px) {
            width: 6rem;
            height: 3.6rem;
            min-height: auto;
          }

          @include theme() {
            background: theme-get("select_bg");
          }

          .select__value-container {
            padding: 0 0 0 0.9rem;

            .select__placeholder {
              margin: 0;

              svg {
                @include theme() {
                  path {
                    stroke: theme-get("textclr");
                  }
                }
              }
            }

            .select__single-value {
              display: flex;
              align-items: center;

              img {
                width: 2rem;
                flex-shrink: 0;
                height: 2rem;
              }

              > span {
                display: none;
              }
            }
          }

          .select__indicator-separator {
            display: none;
          }

          .select__indicators {
            .select__indicator {
              padding: 0 1.3rem 0 0.7rem;

              @media (max-width: 399px) {
                padding: 0 0.6rem;

                svg {
                  width: 1.4rem;
                  height: 1.4rem;
                }
              }
            }

            svg {
              @include theme() {
                path {
                  fill: theme-get("arrow_color");
                }
              }
            }
          }
        }

        .select__menu {
          background-color: transparent;
          width: 17rem;
          box-shadow: none;

          .select__menu-list {
            border-radius: 0.9rem;
            border: 0;
            font-size: 1.6rem;
            font-weight: 500;

            @include theme() {
              background-color: theme-get("list_bg");
              color: theme-get("textclr");
            }

            .select__option {
              cursor: pointer;
              padding: 1.4rem 2rem;
              background-color: transparent;
              transition: 0.2s ease-in-out;
              display: flex;
              align-items: center;

              &:not(:last-child) {
                margin-bottom: 0.1rem;
              }

              > img {
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
              }

              @include theme() {
                color: theme-get("textclr");
              }

              &.select__option--is-focused {
                background-color: transparent;
              }

              &:hover,
              &.select__option--is-selected {
                @include theme() {
                  color: theme-get("textclr");
                  background-color: theme-get("options_bg");
                }
              }
            }
          }
        }
      }

      .theme_btn {
        border: 1px solid $border;
        background-color: rgba($grayLight, 0.14);
        width: 4rem;
        height: 2.2rem;
        border-radius: 5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        padding: 0;
        padding: 0 0.2rem;

        &.active {
          justify-content: flex-end;

          > div {
            background: linear-gradient(
              288.42deg,
              #24214e -18.16%,
              #050520 100.37%
            );

            svg {
              transform: translateY(-0.1rem);
            }
          }
        }

        > div {
          flex-shrink: 0;
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
          background: $white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .toggler {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin-inline-start: 2rem;
        @media (max-width: 479px) {
          margin-inline-start: 1rem;
        }
        span {
          // display: block;
          // width: 3.2rem;
          // height: 0.3rem;
          // border-radius: 4rem;
          display: block;
          width: 2.5rem;
          height: 2px;
          margin: 0.6rem 0;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          background-color: $white;
          transition: 500ms;
          transform-origin: right center;
          transition: 0.3s all ease-in-out;
          position: relative;
          z-index: 991;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          @include theme() {
            background-color: theme-get("textclr");
          }
        }

        &.active {
          &:not(.collapsed) {
            span {
              width: 2.35rem;

              &:first-child {
                transform: rotate(-45deg);
                margin-bottom: 0.6rem;
              }

              &:nth-child(2) {
                transform: scale(0);
              }

              &:last-child {
                transform: rotate(45deg);
                margin: 0.6rem 0;
              }
            }
          }
        }
      }
    }
  }
}
