@import "../../../assets/theme/_var.scss";

.chainPage {
  .chain_left {
    h2 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 1.8rem;
      line-height: 3.2rem;

      @media (max-width: 991px) {
        font-size: 2.4rem;
      }
    }


    .routing {
      border-radius: 1.6rem;
      padding: 5.7rem 3rem;

      @media (max-width: 991px) {
        padding: 1.8rem 2rem;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }

      @media (max-width: 575px) {
        padding: 1.5rem 1.2rem;
      }

      @include theme() {
        background: theme-get("secondryBg");
        color: theme-get("textclr");
      }

      ul {
        display: flex;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        margin-inline: -10px;

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          border-radius: 10px;
          background-color: transparent;
          background-color: $black;
          transition: 200ms ease-in-out;
        }

        &::-webkit-scrollbar-thumb {
          width: 8px;
          transition: 200ms ease-in-out;
          height: 8px;
          background-color: transparent;
          background-color: $black;
          border-radius: 10px;
        }

        li {
          padding-inline: 10px;
          // width: 50%;
          // max-width: 50%;
          flex: 1 0 50%;

          >div {
            display: flex;
            align-items: center;
            justify-content: space-between;


            .token_icon {
              @include theme() {
                background-color: rgba(theme-get('blackclr'), 0.25);
              }

              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;

              >img {
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                border-radius: inherit;
              }
            }

            .arrow {
              display: flex;
              align-items: center;
              // width: 60px;
              flex-grow: 1;
              height: 2px;
              background: linear-gradient(to right, transparent 50%, $black 50%), linear-gradient(to right, $white, $white);
              background-size: 16px 2px, 100% 2px;
              justify-content: flex-end;
              margin-inline: 10px;
              flex-shrink: 0;

              &::before {
                content: "";
                width: 14px;
                height: 14px;
                display: inline-block;
                background-image: url(../../../assets/icons/right-arrow.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;

                @include theme() {
                  filter: theme-get("invert1");
                }
              }
            }

            .routing_details {
              border-radius: 16px;
              padding: 10px;
              // background: $darkBg;
              text-align: center;

              @include theme() {
                background: theme-get("select_bg");
                // background: $gradient;
              }

              h4 {
                white-space: nowrap;
                font-size: 10px;
              }

              .token_icon {
                width: 24px;
                height: 24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 4px;

                @include theme() {
                  background-color: rgba(theme-get("blackclr"), 0.25);
                }

                border-radius: 50%;
                flex-shrink: 0;

                >img {
                  width: 100%;
                  height: 100%;
                }
              }

              p {
                font-size: 14px;
                font-weight: 600;
                line-height: 1;
                margin: 4px 0;
                white-space: nowrap;
              }

              h5 {
                // background: $primaryclr;
                font-size: 12px;
                padding: 4px 10px;
                border-radius: 50px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }


    .chain_norouting {
      border-radius: 1.6rem;
      padding: 5.7rem 17rem;

      @include theme() {
        background: theme-get("secondryBg");
        color: theme-get("textclr");
      }

      @media (max-width: 767px) {
        padding: 4rem 10rem;
      }

      @media (max-width: 575px) {
        padding: 3rem 5rem;
      }

      h3 {
        text-align: center;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 2.9rem;

        @media (max-width: 767px) {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }

    .chain_table {
      width: 100%;
      margin-top: 4.2rem;

      .table_head {
        border-radius: 16px 16px 0px 0px;
        padding: 0rem 4rem;
        height: 5.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(91.21deg,
            rgba(255, 255, 255, 0.042) -1.1%,
            rgba(255, 255, 255, 0.0615) 111.28%),
          linear-gradient(92.62deg, #8f5bff 0.64%, #0057ff 103.8%);

        // background: $gradient2;

        h5 {
          font-size: 1.8rem;
          font-weight: 700;
          margin-right: 2rem;
        }
      }

      .table_in {
        padding: 1.6rem 2rem 0.4rem;
        border-radius: 0rem 0rem 1.2rem 1.6rem;

        @include theme() {
          background: theme-get("secondryBg");
        }

        table {
          padding: 0;
          margin-bottom: 0;

          thead {
            tr {
              th {
                &:last-child {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .cardswap {
    @media (max-width: 991px) {
      margin-top: 2rem;
    }

    .addCard {
      @media (max-width: 991px) {
        max-width: 100%;
      }
    }

    .slip_value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      h3 {
        @include theme() {
          color: rgba(theme-get("textclr"), 0.6)
        }

        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2.4rem;
        text-align: left;
      }
    }

    .swap_btn {
      margin-top: 3.6rem;

      .common_btn {
        height: 6rem;
      }
    }
  }
}