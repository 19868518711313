@import "../../../assets/theme/_var.scss";

.farm {
  .table_card {
    .table_card_head {
      .table_card_filters {
        .common_select {
          .react-select__indicators {
            .react-select__indicator {
              padding: 0 0.1rem;
            }
          }

          &.pool_select {
            text-align: left;
            z-index: 101;
            width: 8.6rem;
          }

          &.sort_by {
            z-index: 101;
            // width: 10.6rem;
            width: 14rem;

            .react-select__control {
              border: none;
            }
          }
        }
      }
    }
  }

  .head_card {
    .head_card_left {
      img {
        width: 30rem;
      }
    }
  }

  .tableFarm {
    border-spacing: 0;

    thead {
      tr {
        th {
          padding-top: 1rem;
          padding-bottom: 1rem;

          &:last-child {
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 1.4rem;
          line-height: 1.6;
          font-weight: 500;
          width: calc(100% / 6);
          white-space: nowrap;

          &:last-child {
            text-align: center;
          }

          .infoToken {
            display: flex;
            align-items: center;

            &_icons {
              margin-right: 0.8rem;
              display: flex;
              align-items: center;
              flex-shrink: 0;

              img {
                flex-shrink: 0;
                width: 1.9rem;
                height: auto;
                border-radius: 50%;

                @include theme() {
                  border: 1px solid theme-get("textclr");
                }

                position: relative;
                z-index: 1;

                // &:last-child {
                //     margin-left: -0.5rem;
                //     position: relative;
                //     z-index: 0;
                // }
              }
            }

            h5 {
              font-size: 1.8rem;
              line-height: 1.6;
              font-weight: 400;
            }
          }

          .earningText {
            h6 {
              font-size: 1.8rem;
              line-height: 1.6;
              font-weight: 400;
            }

            p {
              color: #cbd5e1;
              font-size: 1.6rem;
              line-height: 1.6;
              font-weight: 400;
            }
          }

          .secformData {
            label {

              // color: #a1a1a1;
              @include theme() {
                color: theme-get("tableRowTextColorLight");
              }

              font-size: 1.4rem;
              line-height: 1.6;
              font-weight: 500;
            }

            p {
              font-size: 1.4rem;
              line-height: 1.6;
              font-weight: 500;
              min-height: 2.2rem; // used for alignment of above text
            }
          }

          .earningTable {
            display: flex;
            align-items: center;

            &_left {
              display: flex;
              align-items: center;
              margin-right: 2rem;

              .btnEnable {
                // margin-left: 2rem;
                margin-left: 1rem;

                &:first-of-type {
                  margin-left: 2rem;
                }
              }
            }

            &_right {
              display: flex;
              align-items: center;

              .secformData {
                text-align: left;
              }

              .btnEnable {
                margin-left: 2rem;
              }
            }
          }

          .arrowBtn {
            padding: 0;
            width: max-content;
            min-width: 3rem;

            svg {
              @include theme() {
                path {
                  fill: theme-get("textTd");
                }
              }
            }
          }
        }

        &.tableRowactive {

          // &:first-child {
          td {
            .arrowBtn {
              transform: rotate(180deg);
            }

            &:first-child {
              border-radius: 1rem 0 0 0;
            }

            &:last-child {
              border-radius: 0 1rem 0 0;
            }
          }

          // }

          &.tableRowActiveDesc {
            position: relative;

            .line {
              content: "";
              width: calc(100% - 4rem);
              height: 1px;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              z-index: 101;

              @include theme() {
                background-color: rgba(theme-get("blackclr"), 0.2);
              }
            }

            td {
              &:first-child {
                border-radius: 0 0 0 1rem;
              }

              &:last-child {
                border-radius: 0 0 1rem 0;
              }
            }
          }

          td {

            // background: #102143;
            @include theme() {
              background: theme-get("activeRowBg");
            }

            &:last-child {

              // background: #102143;
              @include theme() {
                background: theme-get("activeRowBg");
              }
            }
          }
        }

        &.spacingTr {
          padding: 0.7rem 0;
          display: inline-block;

          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}