@import "../../../../assets/theme/_var.scss";

.addLiquidity {
  .activeliauidity {
    border-radius: 1rem;
    background: $gradient;
    padding: 1.7rem 1.9rem;
    margin-bottom: 1rem;

    @media (max-width: 991px) {
      padding: 1.5rem;
    }

    h6 {
      font-family: $fontSecondry;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.2;
      text-transform: capitalize;
      margin-bottom: 1.3rem;
      color: $white;

      @media (max-width: 991px) {
        font-size: 1.4rem;
        line-height: 1;
        margin-bottom: 1rem;
      }
    }

    .addBtn {
      display: flex;
      padding: 0;
      height: 4rem;
      width: 100%;
      max-width: 13.2rem;
      margin: auto;
      text-align: center;
      -webkit-text-strokewidth: 1;
      -webkit-text-stroke-color: rgba(245, 247, 250, 0.06);
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.028rem;

      @media (max-width: 991px) {
        padding: 0.6rem 0.4rem;
        max-width: 12rem;
        height: 3.8rem;
      }
    }
  }

  .infofound {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 177%;
    margin: 3.9rem 0;

    @include theme() {
      color: theme-get("textgray");
    }

    @media (max-width: 991px) {
      margin: 2.9rem 0;
    }

    @media (max-width: 575px) {
      margin: 2rem 0;
    }
  }

  .yourLiquidity {
    border-radius: 0.8rem;
    border: 1px solid #ffffff1a;
    background-blend-mode: overlay;
    padding: 2.4rem 1.9rem;

    @include theme() {
      background: theme-get("cardfieldBg");
    }

    @media (max-width: 991px) {
      padding: 2rem 1.5rem;
    }

    .tokens_in_wallet {
      ul {
        padding: 1rem 0rem;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          p,
          h6 {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.2;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            @include theme() {
              color: theme-get("whiteLight");
            }

            span {
              display: flex;
              justify-content: center;
              align-items: center;

              svg,
              img {
                width: 2.8rem;
                height: 2.8rem;
                margin-right: 0.5rem;
                flex-shrink: 0;
                border-radius: 50%;
              }
            }
          }

          h6 {
            font-weight: 600;
          }
        }
      }
    }

    h6 {
      font-family: $fontSecondry;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.2;
      text-transform: capitalize;
      margin-bottom: 1.4rem;

      @media (max-width: 991px) {
        font-size: 1.4rem;
        line-height: 1;
        margin-bottom: 1rem;
      }

      &.yourLiquidityHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >button {
          margin-left: 1rem;
          background-color: transparent;
          outline: none;
          font-size: 1.4rem;
          color: $white;
          border: 1px solid $white;
          border-radius: 0.8rem;
          background: $gradient;
          border: none;
          width: 9rem;
          height: 3rem;
        }
      }
    }

    p {
      color: $textgray;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 177%;
      width: 100%;
      max-width: 44rem;

      a {
        color: $baseclr;
        font-family: $font;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }
}

.backBtn {
  padding: 0;
  margin-right: 2rem;
  height: auto;
}

.plusBtn {
  border: 1px solid #ffffff1a;
  border-radius: 0.8rem;
  padding: 0.82rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.4rem auto;
  background: #191919;

  &::before {
    content: unset;
  }
}

.tokenInfo {
  border-radius: 1rem;
  border: 1px solid #ffffff1a;
  background-blend-mode: overlay;
  padding: 2rem 1.8rem 1.2rem;
  margin-top: 1.5rem;

  @include theme() {
    background: theme-get("cardfieldBg");
  }

  @media (max-width: 991px) {
    padding: 1.5rem 1.5rem 1rem;
  }

  h6 {
    font-family: $fontSecondry;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #ffffff1a;

    @include theme() {
      border-color: theme-get("borderwhite0.1");
    }

    @media (max-width: 991px) {
      font-size: 1.4rem;
      padding-bottom: 1.5rem;
    }

    img {
      margin-right: 1.2rem;

      @media (max-width: 991px) {
        margin-right: 0.5rem;
      }
    }
  }

  .shareList {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1.4rem;

    @media (max-width: 479px) {
      flex-direction: column;
    }

    li {
      @media (max-width: 479px) {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      h5 {
        text-align: center;

        font-size: 1.8rem;
        font-weight: 500;
        line-height: 177%;

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }
      }

      p {
        text-align: center;

        font-size: 1.4rem;
        font-weight: 500;
        line-height: 177%;

        @include theme() {
          color: theme-get("whiteLight");
        }

        @media (max-width: 991px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .tokenList {
    margin-top: 1.4rem;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.2;

        @include theme() {
          color: theme-get("whiteLight");
        }

        @media (max-width: 575px) {
          font-size: 1.2rem;
        }
      }

      p {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.2;

        @include theme() {
          color: theme-get("whiteLight");
        }

        @media (max-width: 575px) {
          font-size: 1.2rem;
        }
      }

      .tokensIcons {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 3rem;
          height: 3rem;

          &.tokensIcons_tophead {
            position: relative;
            z-index: 1;
          }

          &.tokensIcons_backhead {
            position: relative;
            z-index: 0;
            margin-left: -1.3rem;
          }
        }

        span {
          margin-left: 1rem;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1;

          @include theme() {
            color: rgba(theme-get("textclr"), 0.6);
          }
        }
      }
    }
  }
}

.btnSize {
  height: 6rem;
  margin: 3rem 0 1.6rem;
  box-shadow: 8px 8px 24px 0px rgba(9, 13, 20, 0.4);

  @media (max-width: 767px) {
    height: 4.4rem;
  }
}

.liquidityAdd {
  width: 100%;
  max-width: 86%;
  margin: auto;

  &_faq {
    h2 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-bottom: 2.6rem;
      line-height: 1.6;

      @media (max-width: 991px) {
        font-size: 2.4rem;
      }
    }

    .accordion {
      .accordion-item {
        border: none;
        background: transparent;
        margin-bottom: 2rem;

        .accordion-header {
          background: transparent;
          margin: 0;

          .accordion-button {
            border: none;
            font-family: "Poppins-Medium", sans-serif;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1.5;
            justify-content: space-between;
            border-radius: 1.6rem 1.6rem 0 0;
            box-shadow: none;
            padding: 1.6rem 2.2rem;
            padding-bottom: 0.6rem;

            @include theme() {
              background: theme-get("secondryBg");
              color: theme-get("textclr");
            }

            span {
              flex: 0 0 90%;
            }

            svg {
              transition: ease-in-out all 0.3s;
              transform: rotate(180deg);
            }

            &::after {
              content: unset;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }

            &.collapsed {
              background: transparent;
              box-shadow: none;
              padding-top: 0.6rem;

              svg {
                transform: rotate(0deg);
              }
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            border-radius: 0 0 1.6rem 1.6rem;
            padding: 0.6rem 5rem 1.6rem 2.2rem;

            @include theme() {
              background: theme-get("secondryBg");
            }

            p {
              color: #a1a1a1;
              font-size: 1.4rem;
              line-height: 1.6;
              font-weight: 500;
            }
          }

          &.show {
            .accordion-body {
              box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
}

.remove_liquidity_select_token {

  // border-radius: 1.6rem;
  // width: 100%;
  // max-width: 60rem;
  // margin: 0 auto;
  // padding: 2.4rem 3rem;
  // box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  .modal-content {
    .modal-body {
      padding: 2.4rem 1.4rem 1rem 2rem;
    }
  }

  .remove_liquidity_select_token_input {
    border-radius: 1rem;
    padding: 2.3rem 1.8rem;
    border: 1px solid rgba(255, 255, 255, 0.102);

    @include theme() {
      background: theme-get("secondryBg");
    }
  }

  .select_text {
    margin: 1rem 0 2rem;
    font-size: 1.4rem;
  }
}